@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';
@import '../../../assets/styles/typography';
@import '../../../assets/styles/fonts';

.alert__banner {
  z-index: 999;
  position: sticky;
  margin-bottom : 2px;
  padding: 10px;
  background-repeat: round;
  width: auto;
  top: 80px;
  right: 0;
  left: 0;
  box-shadow: 37px 12px 48px rgb(163 175 194 / 18%);
  background-color: $ui-error-tint-3;

  @include media(m-device) {
    top: 56px;
  }

  div {
    text-align: center;
  }
}

.sign_in_alert_banner {
  top: 0 !important;
}

.spending_alert_banner {
  top: 136px !important;

  @include media(m-device) {
    top: 150px !important;
  }
}
