@import '../../../assets/styles/colors';
@import '../../../assets/styles/fonts';
@import '../../../assets/styles/typography';
@import '../../../assets/styles/mixins';


@include render-color;

.body_00 {
  font-family: $primary-reg;
  font-size: $level4;
  line-height: 1.78;
  @include media(m-device) {
    font-size: $level2;
  }
}

.body_01 {
  font-family: $primary-reg;
  font-size: $level3;
  line-height: 1.5;
  @include media(m-device) {
    font-size: $level3;
  }
}

.body_02 {
  font-family: $primary-reg;
  font-size: $level2;
  line-height: 1.71;
  font-weight: 500;
}

.body_03 {
  font-family: $primary-SemiBold;
  font-size: $level4;
  line-height: 1.28;
}

.caption_bold {
  font-family: $primary-bold;
  font-size: $level3;
  line-height: 1.5;
  font-weight: bold;
}

.caption_semi_bold {
  font-family: $primary-SemiBold;
  font-size: $level2;
  line-height: 1.5;
  font-weight: bold;
}

.caption_00 {
  font-family: $primary-medium;
  font-size: $level3;
  line-height: 1.5;
  font-weight: 500;
  @include media(m-device) {
    font-size: $level2;
  }
}

.caption_01 {
  font-family: $primary-medium;
  font-size: $level2;
  line-height: 1.71;
}

.caption_02 {
  font-family: $primary-medium;
  font-size: $level1;
  line-height: 2;
}

.caption_03 {
  font-family: $primary-medium;
  font-size: $level0;
  line-height: 2;
}

.footer {
  font-family: $primary-reg;
  font-size: $level1;
  line-height: 2;
}

.tagline {
  font-family: $primary-medium;
  font-size: $level4;
  @include media(m-device) {
    font-size: $level2;
  }
}

.button_small {
  font-family: $primary-medium;
  font-size: $level2;
  word-break: break-word;
}

.button_default {
  font-family: $primary-medium;
  font-size: $level3;
}

.button_large {
  font-family: $primary-medium;
  font-size: $level4;
}

.label {
  font-family: $primary-SemiBold;
  font-size: $level3;
  line-height: 1.71;
}

.mini_label {
  font-family: $primary-SemiBold;
  font-size: $level2;
}


.hint {
  font-family: $primary-medium;
  font-size: $level2;
}

.warning {
  font-family: $primary-medium;
  font-size: $level1;
  text-align: center;
  margin-top: 10px;
}

.caption_bold_small {
  font-family: $primary-bold;
  font-size: $level2;
  line-height: 2;
}

.caption_bold_medium {
  font-family: $primary-bold;
  font-size: $level6;
  line-height: 2;
}

