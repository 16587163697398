@import "reset";
@import "colors";
@import "fonts";
@import "mixins";
@import "typography";

:global {
  //progress bar when changing page
  #nprogress .bar {
    background: $brand-primary;
  }

  #nprogress .peg {
    box-shadow: 0 0 10px $brand-primary, 0 0 5px $brand-primary;
    opacity: 1.0;
  }

  //slider
  .slick-dots li {
    width: 10px;
  }

  .react-responsive-modal-overlay,
  .react-responsive-modal-container,
  .react-responsive-modal-modal {
    animation-fill-mode: forwards !important;
  }
}
:root {
  --percentage: 0;
}
* {
  font-size: 16px;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}


h1 {
  font-family: $primary-bold;
  font-size: $level12;
  line-height: 1.14;
  color: $txt-primary;
  @include media(m-device) {
    font-size: $level8;
    line-height: 1.19;
  }
}

h2 {
  font-family: $primary-bold;
  font-size: $level11;
  line-height: 1.17;
  color: $txt-primary;
  @include media(m-device) {
    font-size: $level7;
    line-height: 1.33;
  }
}

h3 {
  font-family: $primary-bold;
  font-size: $level8;
  line-height: 1.19;
  color: $txt-primary;
  @include media(m-device) {
    font-size: $level6;
    line-height: 1.27;
  }

}

h4 {
  font-family: $primary-SemiBold;
  color: $txt-primary;
  font-size: $level7;
  line-height: 1.33;
  @include media(m-device) {
    font-size: $level4;
    line-height: 1.33;
  }
}

h5 {
  font-family: $primary-SemiBold;
  font-size: $level5;
  line-height: 1.2;
  color: $txt-primary;
  @include media(m-device) {
    font-size: $level3;
    line-height: 1.5;
  }
}

a {
  color: $black;
  text-decoration: none;
}

