@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/fonts';
@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/mixins';

.toast {
  display: flex;
  align-items: center;
  z-index: 999999999;
  width: 360px;
  min-height: 56px;
  height: auto;
  padding: 16px;
  border-radius: 4px;

  :global(.react-toast-notifications__toast__icon-wrapper) {
    display: none;
  }


  :global(.react-toast-notifications__toast__content) {
    display: flex !important;
    align-items: center !important;
    min-height: auto !important;
    font-family: $primary-reg !important;
    font-size: $level2 !important;
    min-width: 90% !important;
  }

  &__success {
    color: $ui-success !important;
    background-color: $ui-success-tint-1 !important;
  }

  &__error {
    color: $ui-error !important;
    background-color: $ui-error-tint-1 !important;
  }

  &__warning {
  }

  &__info {
  }

  &__close_button {
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: none;
    cursor: pointer;
    outline: none;
    background: $white;
    right: 10px;
    top: 10px;
    font-size: 28px;
    line-height: 13px;

    img {
      width: 15px;
      height: 15px;
    }

    &:hover,
    &:focus {
      outline: none;
    }
  }
}
